<template>
  <path
    d="M5.5 4.5H5.2C4.0799 4.5 3.51984 4.5 3.09202 4.71799C2.7157 4.90973 2.40973 5.2157 2.21799 5.59202C2 6.01984 2 6.5799 2 7.7L2 11.3C2 12.4201 2 12.9802 2.21799 13.408C2.40974 13.7843 2.7157 14.0903 3.09202 14.282C3.51985 14.5 4.0799 14.5 5.2 14.5H8.8C9.9201 14.5 10.4802 14.5 10.908 14.282C11.2843 14.0903 11.5903 13.7843 11.782 13.408C12 12.9802 12 12.4201 12 11.3V10"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M7.83175 2.65951L7.83175 2.65951C7.90365 2.51839 8.01839 2.40365 8.15951 2.33175C8.19659 2.31285 8.27213 2.28459 8.47588 2.26795C8.68838 2.25058 8.96759 2.25 9.4 2.25H12.6C13.0324 2.25 13.3116 2.25058 13.5241 2.26795C13.7279 2.28459 13.8034 2.31285 13.8405 2.33175C13.9816 2.40365 14.0963 2.51839 14.1683 2.65951L14.7344 2.37103L14.1683 2.65951C14.1871 2.69659 14.2154 2.77213 14.2321 2.97588C14.2494 3.18838 14.25 3.46758 14.25 3.9V6.1C14.25 6.53241 14.2494 6.81162 14.2321 7.02412C14.2154 7.22787 14.1871 7.30341 14.1683 7.34049C14.0963 7.48161 13.9816 7.59635 13.8405 7.66825C13.8034 7.68715 13.7279 7.71541 13.5241 7.73205C13.3116 7.74942 13.0324 7.75 12.6 7.75H9.4C8.96759 7.75 8.68838 7.74942 8.47588 7.73205C8.27213 7.71541 8.19659 7.68715 8.15951 7.66826C8.01839 7.59635 7.90365 7.48162 7.83175 7.34049L7.17255 7.67637L7.83175 7.34049C7.81285 7.30341 7.7846 7.22787 7.76795 7.02412C7.75059 6.81162 7.75 6.53242 7.75 6.1L7.75 3.9C7.75 3.46759 7.75058 3.18838 7.76795 2.97588C7.78459 2.77213 7.81285 2.69659 7.83175 2.65951Z"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
</template>
